// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-404-js": () => import("/opt/build/repo/src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-index-js": () => import("/opt/build/repo/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-yhteys-js": () => import("/opt/build/repo/src/templates/yhteys.js" /* webpackChunkName: "component---src-templates-yhteys-js" */)
}

